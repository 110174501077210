// This file is generated via scripts/build-static-config.js
  // Do not modify directly as changes will be overwritten in CI

  export const CONFIGURATION = {
    country: 'de',
    brand: 'bk',
    env: 'prod',
    graphqlEnv: 'prod',
    graphqlGatewayEnv: '',
    sanityEnv: 'prod',
    platform: 'web',
    commitRef: 'd94b67faff85092576f39932ddd2c88dd83af0cf',
    releaseTagRef: '40e3945',
    releaseTimestamp: '1731396024',
    sanityDataset: '',
    sanityProjectId: 'czqk28jt',
    appFlowBuildId: '',
    clickTrackingDomain: 'clicks.burgerking.de',
    adyen: {
      clientKey: 'live_L6BRV3FK4FFZVN2XFXYC6WCMRALGGK6O',
      environment: 'live',
      urlPrefix: 'https://checkoutshopper-live.adyen.com',
      ideal: {
        idealBanks: [],
      }
    },
    apiKeys: {
      amplitude: '',
      branch: 'key_live_ils8AXQE1p3l7mui3KnsocooECeHxcIa',
      braze: '550413e9-770e-4730-adf6-be1cab4b1a74',
      brazeCluster: 'sdk.fra-01.braze.eu',
      bringg: '',
      googleMaps: 'AIzaSyBda_YSvg2hNZW8SGU-ALdpnYDq_Lvu-mc',
      tomtom: '',
      launchDarkly: '64468746bb88121262dd114a',
      launchDarklyMobileKey: 'mob-e2b20369-e09f-4fc2-bbbb-851e31cf8abf',
      mParticle: 'eu1-acfb9c5b97fc3845bb662dcc8d7116f1',
      cookiebot: '',
      bloomreachTarget: '',
      bloomreachProjectToken: '',
    },
    apple: {
      merchantId: 'merchant.com.bk.native.de.prod',
      migrationMerchantId: '',
      paymentsNetworks: ['AmEx','MasterCard','Visa','Discover'],
      prepaidMerchantId: '',
      migrationPrepaidMerchantId: '',
      socialLogin: {
        clientId: 'com.bk.de.sociallogin',
        redirectURI: 'https://burgerking-de.firebaseapp.com/__/auth/handler',
      },
    },
    branch: {
      domains: ['burgerkingde.test-app.link','burgerkingde-alternate.test-app.link','burgerkingde.app.link','burgerkingde-alternate.app.link']
    },
    checkoutDotCom: {
      publicKey: '',
    },
    facebook: {
      socialLogin: {
        appId: '678112850804983',
        redirectURI: 'https://www.burgerking.de/',
      },
    },
    aws: {
      gqlApiUrl: 'https://euc1-prod-bk.rbictg.com/graphql',
      gqlGatewayApiUrl: 'https://euc1-prod-bk-gateway.rbictg.com/graphql',
      region: 'eu-central-1',
      userPoolClientId: '62ev1gv53cp9bckrt5gdinrqmj',
      userPoolId: 'eu-central-1_dwCOsnxKY',
    },
    bringg: {
      region: '6',
    },
    datadog: {
      logs: {
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
      rum: {
        applicationId: 'd2ad1579-fd2d-4500-a5d1-baa39289ebe4',
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
    },
    firstData: {
      corporateStoreId: '',
    },
    forter: {
      siteId: '821dbf709728',
    },
    google: {
      paymentsEnvironment: 'prod',
      paymentsGateway: 'computop',
      marketPaymentsGatewayMerchantId: '',
      paymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPaymentsGateway: '',
      migrationPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      paymentsNetworks: ['AMEX','MASTERCARD','VISA','DISCOVER','JCB'],
      prepaidPaymentsGateway: '',
      prepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPrepaidPaymentsGateway: '',
      migrationPrepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      socialLogin: {
        clientId: '84786735498-jocrbgsaf1n0clq1pikmm4o5q7p8jgu9.apps.googleusercontent.com',
        redirectURI: 'https://burgerking-de.firebaseapp.com/__/auth/handler',
      },
    },
    googleAdManager: {
      campaignId: '',
    },
    googleTagManager: {
      containerId: '',
    },
    oneTrust: {
      cookieCompliance: {
        consentCategoryBranch: 'C0004',
        consentCategoryBraze: 'C0003',
        consentCategoryMParticle: 'C0001',
        globalConsentState: 'Opt-In',
        scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        scriptId: '3445d691-3ebe-4414-948a-a8281acf4595',
      },
      deleteAccountUrl: 'https://privacyportal-de.onetrust.com/webform/d1fd6c0f-46d0-43f3-b672-3bfdec055fe8/253af85b-870f-45c8-93bb-b1bda2ef5b90',
    },
    pypestream: {
      scriptUrl: '',
      appId: '',
    },
    restaurants: {
      displayEnv: 'prod',
      validMobileOrderingEnvs: ['alpha','beta','live'],
    },
    urls: {
      braze: 'https://customer.iad-03.braze.com/api/v3',
      firstData: 'https://api.payeezy.com',
      webBaseUrl: '',
    },
    tokenEx: {
      publicKey: '',
    },
    vrPayment: {
      braintreeTokenizationKey: 'production_pgq6827b_3zz878p6xyfg94v8',
    },
    orbital: {
      baseUrl: '',
      subscriberId: '',
      pieFormat: '',
      mode: '',
    },
    timeFormat: 'HH:mm',
    dateFormat: '',
    addressFormat: '',
    maxCartQuantity: 25,
    appStoreDeeplink: '',
    iosDeepLink: '',
    androidDeepLink: '',
    cookieBanners: {
      cookieBanner:'oneTrust',
      scriptId: '',
      oneTrust: {
        cookieCompliance: {
          consentCategoryBranch: 'C0004',
          consentCategoryBraze: 'C0003',
          consentCategoryMParticle: 'C0001',
          consentCategoryDdRum: 'C0002',
          globalConsentState: 'Opt-In',
          scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
          scriptId: '01902ca7-7fe5-7e12-a463-ddc5483bd3a5',
        },
      },
    }
  };